import { markRaw } from "vue";

export const DATA_TYPES = {
    string: "string",
    number: "number",
    date: "date",
    datetime: "datetime",
    boolean: "boolean",
    object: "object"
};

export const FILTER_TYPES = {
    default: "default",
    text: "text",
    tags: "tags",
    select: "select"
};

export const GridUtil = {
    parseFilterValue: (column, value) => _.isNil(value)
        ? null
        : (column?.dataType === DATA_TYPES.date
            || column?.dataType === DATA_TYPES.datetime)
            ? new Date(value)
            : value,

    parseRangeValues(column, values) {
        let startValue = null;
        let endValue = null;

        if(_.isArray(values)){
            startValue = this.parseFilterValue(column, values[0]);
            endValue = this.parseFilterValue(column, values[1]);
        }
        else
            startValue = values || null;
        return [startValue, endValue];
    },

    getStateFromExpr(filterExpr=[], startState={}) {
        if(_.isEmpty(filterExpr)) return {};
        const appendFieldExpr = (expr, state={}, opr="") => {
            if(_.isString(expr)) return;
            if(_.isString(expr[0])) {
                let name = expr[0],
                    value = expr[2],
                    filters = expr.slice();
                if(_.hasIn(state, name)) {
                    if(_.isArray(state[name].value)) {
                        if(_.includes(state[name].value, value)) return state;
                        state[name].value.push(value);
                    }
                    else {
                        if(state[name].value === value) return state;
                        state[name].value = [state.value,value];
                    }
                    if(_.isArray(state[name].filters[0])) {
                        state[name].filters.push(opr);
                        state[name].filters.push(filters);
                    }
                    else {
                        state[name].filters = [state[name].filters.slice(), opr, filters];
                    }
                }
                else {
                    state[name] = { filters, value };
                }
            }
            else if(_.isArray(expr[0])) {
                _.forEach(expr, (childExpr,idx) => {
                    if(_.isString(childExpr)) return;
                    let prevOpr = idx > 0 ? expr[idx-1] : "";
                    appendFieldExpr(childExpr, state, prevOpr);
                });
            }
            return state;
        };
        let resultState = _.cloneDeep(startState);
        return appendFieldExpr(filterExpr, resultState);
    },

    getRangeFilterExpression(column, values) {
        let parsedValues = this.parseRangeValues(column, values);
        let startValue = parsedValues[0];
        let endValue = parsedValues[1];
        if (startValue != null) startValue.setHours(0,0,0,0);
        if (endValue != null) endValue.setHours(23, 59, 59, 999);

        let filters = [];
        if(!_.isNil(startValue) || !_.isNil(endValue)) {

            if(_.isNil(startValue))
                filters = column.createFilterExpression(endValue, "<=");
            else if(_.isNil(endValue))
                filters = column.createFilterExpression(startValue, ">=");
            else
                filters = column.createFilterExpression([startValue, endValue], "between");
        }
        filters.columnIndex = column.index;
        filters.filterValues = [startValue, endValue];
        return filters;
    },

    getListFilterExpression(column, values, listOperator="or", valueOperator="=") {
        if(_.isEmpty(values)) return null;
        let filters = [];
        if(values.length > 1) {
            _.forEach(values, (v,i) => {
                if(i > 0) filters.push(listOperator);
                filters.push(column.calculateFilterExpression(v, valueOperator));
            });
        }
        else
            filters = column.calculateFilterExpression(values[0], valueOperator);
        filters.columnIndex = column.index;
        filters.filterValues = values.slice();
        return filters;
    },

    getCombinedFilterExpression(filterData) {
        if(_.isEmpty(filterData)) return [];
        let filters = [];
        let filterKeys = _.keys(filterData);
        if(filterKeys.length > 1) {
            _.forEach(filterData, v => {
                if(filters.length > 0) filters.push("and");
                filters.push(v.filters);
            });
        }
        else
            filters = filterData[filterKeys[0]].filters;
        return filters;
    }
};

export class RqGridFilterOptions {
    constructor(options, dataField) {
        let opts = options || {};
        this.filterType = opts.filterType || "default";
        this.listOperator = opts.listOperator || "or";
        this.valueOperator = opts.valueOperator || "=";
        this.dataSource = opts.dataSource || null;
        this.displayExpr = opts.displayExpr || null;
        this.valueExpr = opts.valueExpr || null;
        this.noDataText = opts.noDataText || "No options available.";
        this.filterDataField = opts.filterDataField || dataField;
        this.decimals = _.parseNumber(opts.decimals, 0);
        this.minSearchLength = _.parseNumber(opts.minSearchLength, 0);
        this.maxDisplayedTags = _.parseNumber(opts.maxDisplayedTags, 1);
        this.placeholder = opts.placeholder || null;
        this.persistSelectedItems = _.parseBool(opts.persistSelectedItems, false);
        this.showDropDownButton = _.parseBool(opts.showDropDownButton, true);
        this.showDataBeforeSearch = _.parseBool(opts.showDataBeforeSearch, true);

        //"markRaw" is used to prevent making a component obj a reactive element
        this.itemTemplate = _.isNil(opts.itemTemplate) ? null : markRaw(opts.itemTemplate);

        this.inputDisabled = _.isFunction(opts.inputDisabled) ? opts.inputDisabled : _.parseBool(opts.inputDisabled);
        this.inputDisabledMessage = opts.inputDisabledMessage || null;

        this.disabled = _.isFunction(opts.disabled) ? opts.disabled : _.parseBool(opts.disabled);
        this.disabledTooltip = opts.disabledTooltip || null;
    }

    isInputDisabled(args) {
        return _.isFunction(this.inputDisabled) ? this.inputDisabled(args) : this.inputDisabled;
    }

    isDisabled(args) {
        return _.isFunction(this.disabled) ? this.disabled(args) : this.disabled;
    }
}

export class RqGridGroupingOptions {
    constructor(options) {
        let opts = _.isBoolean(options) ? { enabled: options, visible: options } : options || {};
        this.inputId = opts.inputId || _.uniqueId("tbx_grid_column_grouping_");
        this.automationId = opts.automationId || opts.automation_id || "tbx_grid_column_grouping";
        this.placeholder = opts.placeholder || "Group by...";
        this.size = opts.size || "sm";
        this.searchEnabled = _.parseBool(opts.searchEnabled, true);
        this.contextMenuEnabled = _.parseBool(opts.contextMenuEnabled, true);
        this.showDropDownButton = _.parseBool(opts.showDropDownButton, true);
        this.showClearButton = _.parseBool(opts.showClearButton, true);
        this.multiline = _.parseBool(opts.multiline);
        this.maxDisplayedTags = _.parseNumber(opts.maxDisplayedTags, null) || 4;
        this.maxSelectedGroups = _.parseNumber(opts.maxSelectedGroups, null) || 3;
        this.enabled = _.parseBool(opts.enabled);
        this.visible = _.parseBool(opts.visible);

        this.disabled = _.isFunction(opts.disabled) ? opts.disabled : _.parseBool(opts.disabled);
        this.disabledTooltip = opts.disabledTooltip || null;
    }

    get idAttrs() { return { id: this.inputId, automation_id: this.automationId }; }
    get small() { return this.size === "sm"; }

    isDisabled(args) {
        return _.isFunction(this.disabled) ? this.disabled(args) : this.disabled;
    }
}

export class RqGridColumnOptions {
    constructor(options) {
        let opts = options || {};

        //universal function returning a text string to highlight within the cell (uses a cellTemplate)
        this.highlightText = _.isFunction(opts.highlightText) ? opts.highlightText : null;

        //flag enabling the functionality to highlight text matching the ActionDatGrid.searchValue string within the cell (uses a cellTemplate)
        this.highlightSearchText = _.parseBool(opts.highlightSearchText);

        //flag enabling adding the "truncate-text" CSS class to an element surrounding the cell text (uses a cellTemplate)
        this.truncateCellText = _.parseBool(opts.highlightSearchText);
    }
}
