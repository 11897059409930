import { mapActions } from "vuex";
import { RQ_SECTION_ACTIONS as ACTIONS } from "./actions";
const DEFAULT_GROUP = "global";
const ICON_ALIGNMENT = { Right: "right", Left: "left" };
export default {
    props: {
        title: { default: null },
        collapsible: { type: Boolean, default: false },
        collapsed: { type: Boolean, default: false },
        expanded: { type: Boolean, default: true },
        sectionKey: { type: [Number,String], default: 0 },
        sectionGroup: { type: String, default: DEFAULT_GROUP },
        hideCollapseIcon: { type: Boolean, default: false },
        hideCollapseTooltip: { type: Boolean, default: false },
        collapseIconRight: { type: Boolean, default: false },
        collapseIconLeft: { type: Boolean, default: false },
        titleFocusEnabled: { type: Boolean, default: false },
        idAttrs: { type: Object, default: () => ({}) },
        persistState: { type: Boolean, default: true },
    },
    data() {
        return {
            collapseTooltipVisible: false,
            expandedValue: this.expanded,
            defaultIconAlignment: ICON_ALIGNMENT.Left,
            defaultIdAttrs: {
                collapseComponent: _.uniqueId("rq-section-collapse-"),
                collapseIcon: _.uniqueId("rq-section-collapse-icon-"),
                titleAction: ""
            },
            persistedStateKey: null
        };
    },
    computed: {
        collapseId() { return this.getIdAttr("collapseComponent"); },
        collapseIconId() { return this.getIdAttr("collapseIcon"); },
        titleActionId() { return this.getIdAttr("titleAction"); },
        storedExpandedValue() {
            let groups = _.get(this, "$store.state.ui.rqSections.groups", null) || {};
            return _.getBool(groups, `["${this.sectionGroup}"]["${this.collapseId}"]`, true);
        },
        collapseAlignValue() {
            if(this.collapseIconLeft) return ICON_ALIGNMENT.Left;
            if(this.collapseIconRight) return ICON_ALIGNMENT.Right;
            return this.defaultIconAlignment;
        },
        collapsibleClasses() {
            return {
                "rq-collapsible": this.collapsible,
                "rq-collapsible-left": this.collapsible && this.collapseAlignValue === ICON_ALIGNMENT.Left,
                "rq-collapsible-right": this.collapsible && this.collapseAlignValue === ICON_ALIGNMENT.Right,
                "rq-collapsed": !this.expandedValue
            };
        },
        collapseIcon() {
            switch(this.collapseAlignValue) {
                case ICON_ALIGNMENT.Right: return "fas fa-caret-down";
                case ICON_ALIGNMENT.Left: return "fas fa-caret-right";
            }
            return "";
        },
        collapseIconRotation() {
            return (this.expandedValue && this.collapseAlignValue === ICON_ALIGNMENT.Left)
                || (!this.expandedValue && this.collapseAlignValue === ICON_ALIGNMENT.Right)
                ? 90 : 0;
        },
        collapseIconTooltip() {
            return this.expandedValue
                ? "Collapse Section"
                : "Expand Section";
        },
        isExpandedValue: {
            get() { return this.expandedValue; },
            set(val) { this.expandedValue = val; }
        }
    },
    watch: {
        sectionGroup(newValue, oldValue) {
            if(newValue === oldValue) return;
            _.invoke(this, ACTIONS.ADD_SECTION, {
                key: this.collapseId,
                groupName: newValue,
                expanded: this.expandedValue
            });
        },
        expanded(newValue, oldValue) {
            if(newValue === oldValue || newValue === this.expandedValue) return;
            this.expandedValue = newValue;
        },
        storedExpandedValue(newValue, oldValue) {
            if(newValue === oldValue || newValue === this.expandedValue) return;
            this.expandedValue = newValue;
        },
        expandedValue(newValue, oldValue) {
            if(newValue === oldValue) return;
            if(newValue !== this.expanded)
                this.$emit("update:expanded", newValue);
            this.setPersistedState();
            if(newValue === this.storedExpandedValue) return;
            _.invoke(this, ACTIONS.UPDATE_SECTION, {
                key: this.collapseId,
                groupName: this.sectionGroup,
                expanded: newValue
            });
        }
    },
    created() {
        if(!this.collapsible) return;
        this.initState();
    },
    beforeUnmount() {
        if(!this.collapsible) return;
        this.setPersistedState(true);
        _.invoke(this, ACTIONS.REMOVE_SECTION, this.collapseId);
    },
    methods: {
        ...mapActions([
            ACTIONS.ADD_SECTION,
            ACTIONS.UPDATE_SECTION,
            ACTIONS.REMOVE_SECTION
        ]),
        initState() {
            if(this.persistState) {
                this.createPersistedStateKey();

                let persistedState = this.$rqStore.getItem(this.persistedStateKey);
                this.expandedValue = _.getBool(persistedState, "expanded", !this.collapsed);
            }
            else if(!this.collapsed) {
                //at this point "expandedValue" is already set to "expanded", so only set if "collapsed" is not the default of "false"
                this.expandedValue = false;
            }
            let groupState = this.getGroupState();
            _.invoke(this, ACTIONS.ADD_SECTION, groupState);
        },
        createPersistedStateKey() {
            let routeComponents = _.flatMap(this.$route.matched, r => r.components);
            let routeComponent = _.isEmpty(routeComponents) ? {} : _.last(routeComponents);
            let routeComponentName = _.get(routeComponent, "name", null);
            let sectionKey = _.isEmpty(this.title) ? this.sectionKey : _.kebabCase(this.title);
            let baseKey = `rq-section-collapse-${this.sectionGroup}-${sectionKey}`;
            this.persistedStateKey = _.isEmpty(routeComponentName) ? baseKey : `${baseKey}-${_.kebabCase(routeComponentName)}`;
        },
        setPersistedState(isFinal=false) {
            if(!this.persistState || !this.collapsible) return;
            let currentState = this.getGroupState();
            if(isFinal && (currentState.expanded === !this.collapsed)) {
                //if this is the final update from beforeUnmount and the expanded state equals the default expanded state, remove from storage to keep localStorage clean
                this.$rqStore.removeItem(this.persistedStateKey);
            }
            else {
                this.$rqStore.setItem(this.persistedStateKey, currentState);
            }
        },
        getGroupState(){
            return {
                key: this.collapseId,
                groupName: this.sectionGroup,
                expanded: this.expandedValue
            };
        },
        expand() { this.expandedValue = true; },
        collapse() { this.expandedValue = false; },
        toggleCollapse() {
            if(!this.collapsible) return;
            this.collapseTooltipVisible = false;
            this.expandedValue = !this.expandedValue;
        },
        bubbleEvent(eventName, eventArgs) {
            this.$emit(eventName, eventArgs);
        },
        slotIsEmpty(slotName) {
            return _.slotIsEmpty(this, slotName);
            // return _.isEmpty(this.$slots) || !_.has(this.$slots, slotName) || (_.isEmpty(_.get(this, `$slots[${slotName}][0].text`, null)) && _.isEmpty(_.get(this, `$slots[${slotName}][0].children`, null)));
        },
        onTitleAction(e) {
            if(!this.titleFocusEnabled && e.type !== "click") return;
            this.toggleCollapse();
            this.$emit("title-action");
        },
        onToggleCollapse() {
            this.toggleCollapse();
        },
        getIdAttr(name) {
            let resultId = _.get(this, `idAttrs.${name}`, null) || _.get(this, `defaultIdAttrs.${name}`, null);
            if(!_.isEmpty(resultId)) return resultId;
            return `section_${_.snakeCase(name)}_${_.snakeCase(this.title)}`;
        }
    }
};