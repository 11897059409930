import { ref } from "vue";

export function useRqDxDataGrid() {        
    const gridId = _.uniqueId("rq-data-grid-");
    const gridElement = ref(null);
    const gridInstance = ref(null);    
    const dxConfig = ref({});
    const dxConfigReady = ref(false);
    const dxInitialized = ref(false);
    const dxLoaded = ref(false);
    const dxReady = ref(false);
    const componentMounted = ref(false);
    const selectedRowKeys = ref([]);

    return {
        gridId,
        gridInstance,
        gridElement,
        dxConfig,
        dxConfigReady,
        dxInitialized,
        dxLoaded,
        dxReady,
        componentMounted,
        selectedRowKeys
    };
}