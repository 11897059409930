<template>
    <DxPopover
        v-if="isVisible"
        ref="popoverComponent"
        :target="targetSelector"
        :container="containerSelector"
        :position="popoverPosition"
        :wrapper-attr="popoverWrapperAttr"
        :visible="true"
        @hidden="isVisible=false">
        <div
            :id="filterContainerId"
            :class="{ 'filter-container':true, 'p-2':!isDateFilter }"
            @keyup="onKeyUp">
            <template v-if="isDateFilter">
                <rq-date-filter
                    ref="dateFilterComponent"
                    :id="`${target}_rq_date_range`"
                    :automation_id="`dtp_${automationIdPrefix}`"
                    v-model:start-date="rangeStartValue"
                    v-model:end-date="rangeEndValue"
                    :disabled="isInputDisabled"
                    v-model="selectedDateRange"
                    @custom-range-selected="onCustomDateRangeSelected"
                    @apply="onApply"
                    @remove="onRemove"
                    @cancel="onCancel"
                />
            </template>
            <div v-else :class="{ 'form-group mt-1 filter-form-group':true, 'rq-show-clear-button': showClearButton }">
                <template v-if="isBoolType">
                    <rq-radio-group
                        id="rdo_bool_filter"
                        name="rdo_bool_filter"
                        :automation_id="`rdo_${automationIdPrefix}_bool_filter`"
                        :options="[
                            { automation_id:`chk_${automationIdPrefix}_yes`, value: false, text: 'NO' },
                            { automation_id:`chk_${automationIdPrefix}_no`, value: true, text: 'YES' }
                        ]"
                        :disabled="isInputDisabled"
                        v-model="boolValue"
                    />
                </template>
                <template v-else-if="isSelectBoxInput">
                    <dx-select-box
                        ref="dxSelectBoxInput"
                        class="form-control form-control-sm"
                        :input-attr="$utils.idAttrs(`drp_${automationIdPrefix}`)"
                        :data-source="lookupDataSource"
                        :drop-down-options="{
                            wrapperAttr: { id:`${filterContainerId}_dropdown_popup` }
                        }"
                        :display-expr="lookupDisplayExpr"
                        :value-expr="lookupValueExpr"
                        :show-clear-button="true"
                        :search-enabled="true"
                        :no-data-text="rqFilterOptions.noDataText"
                        :placeholder="filterPlaceholder"
                        :disabled="isInputDisabled"
                        v-model="textValue"
                    />
                </template>
                <template v-else-if="isTagBoxInput">
                    <dx-tag-box
                        ref="dxTagBoxInput"
                        class="form-control form-control-sm"
                        :data-source="lookupDataSource"
                        :input-attr="$utils.idAttrs(`tb_${automationIdPrefix}`)"
                        :drop-down-options="{
                            wrapperAttr: { id:`${filterContainerId}_dropdown_popup` }
                        }"
                        :display-expr="lookupDisplayExpr"
                        :value-expr="lookupValueExpr"
                        :search-enabled="true"
                        :show-selection-controls="true"
                        :show-clear-button="true"
                        :max-displayed-tags="rqFilterOptions.maxDisplayedTags"
                        :show-drop-down-button="rqFilterOptions.showDropDownButton"
                        :show-data-before-search="rqFilterOptions.showDataBeforeSearch"
                        :no-data-text="rqFilterOptions.noDataText"
                        :min-search-length="rqFilterOptions.minSearchLength"
                        :placeholder="filterPlaceholder"
                        :selected-items="selectedTagItems"
                        apply-value-mode="useButtons"
                        :item-template="lookupItemTemplate"
                        :disabled="isInputDisabled"
                        :multiline="false"
                        v-model="tagBoxValue"
                        @valueChanged="onTagValueChanged">
                        <template #rq-custom-item="{ data, index }">
                            <component v-if="hasItemTemplate" :is="rqFilterOptions.itemTemplate" :item="data" :index="index" />
                        </template>
                    </dx-tag-box>
                </template>
                <template v-else>
                    <div class="row">
                        <label class="col col-auto col-form-label me-auto">Enter filter value:</label>
                        <div class="col col-auto form-group-action">
                            <b-btn
                                v-show="isNumberType && !isTagBoxInput && !isSelectBoxInput"
                                :id="`${automationIdPrefix}_type_toggle`"
                                :automation_id="`btn_${automationIdPrefix}_type_toggle`"
                                variant="link"
                                size="sm"
                                class="px-0"
                                :disabled="isInputDisabled"
                                @click="useNumberRange=!useNumberRange">{{numberInputToggleDisplay}}</b-btn>
                        </div>
                    </div>
                    <div v-if="useNumberRange" class="input-range">
                        <rq-number-range
                            ref="numberRangeInput"
                            :id="`txt_${automationIdPrefix}`"
                            :automation_id="`txt_${automationIdPrefix}`"
                            :prefix="numberPrefix"
                            :decimals="numberDecimals"
                            size="sm"
                            :disabled="isInputDisabled"
                            v-model:start-value="rangeStartValue"
                            v-model:end-value="rangeEndValue"
                            allow-nulls
                        />
                    </div>
                    <!--
                        No prefix/decimals/formatting necessary since this is a "contains" filter
                        <rq-input-number
                            v-else-if="isNumberType"
                            :prefix="numberPrefix"
                            :decimals="numberDecimals"
                            size="sm"
                            v-model="textValue"
                        />
                    -->
                    <input v-else
                        ref="textInput"
                        :id="`txt_${automationIdPrefix}`"
                        :automation_id="`txt_${automationIdPrefix}`"
                        class="form-control form-control-sm"
                        :type="isNumberType ? 'number' : 'text'"
                        :placeholder="filterPlaceholder"
                        :disabled="isInputDisabled"
                        v-model="textValue">
                    <rq-icon-button
                        :id="`btn_${automation_id}_clear`"
                        :automation_id="`btn_${automation_id}_clear`"
                        class="rq-clear-value"
                        icon="fas fa-times-circle"
                        :disabled="isInputDisabled"
                        @click="clear" />
                </template>
            </div>
            <div class="row rq-filter-footer" v-if="!isInputDisabled && !isDateType && !isDateTimeType">
                <div v-show="fieldHasFilter()" class="col-auto pe-0">
                    <b-btn
                        :id="`btn_${automationIdPrefix}_remove`"
                        :automation_id="`btn_${automationIdPrefix}_remove`"
                        variant="link"
                        size="sm"
                        @click="onRemove">Remove</b-btn>
                </div>
                <div class="col-auto ms-auto">
                    <b-btn
                        :id="`btn_${automationIdPrefix}_cancel`"
                        :automation_id="`btn_${automationIdPrefix}_cancel`"
                        variant="secondary"
                        size="sm"
                        @click="onCancel">Cancel</b-btn>
                    <span v-rq-tooltip.hover.top :title="inputDisabledTooltip">
                        <b-btn
                            :id="`btn_${automationIdPrefix}_apply`"
                            :automation_id="`btn_${automationIdPrefix}_apply`"
                            variant="dark"
                            size="sm"
                            class="ms-2"
                            :disabled="isInputDisabled"
                            @click="onApply">Apply</b-btn>
                    </span>
                </div>
            </div>
        </div>
    </DxPopover>
</template>

<script>
/*
        :wrapper-attr="{
            class: popoverCustomClass
        }"
*/
    import DxSelectBox from "devextreme-vue/select-box";
    import DxTagBox from "devextreme-vue/tag-box";
    import { formatDate } from "../DateUtil";
    import { DATA_TYPES, FILTER_TYPES, GridUtil, RqGridFilterOptions } from "./GridUtil";

    import RqDateFilter from "./RqDateFilter.vue";

    export default {
        props: {
            automation_id: { type: String, default: "" },
            target: { type: String, default: "" },
            container: { type: String, default: null },
            column: { type: Object, default: null },
            gridInstance: { type: Object, default: () => ({}) },
            visible: { type: Boolean, default: false },
            filterState: { type: Object, default: null },
            clearBeforeNextApply: { type: Boolean, default: false }
        },
        components: { RqDateFilter, DxSelectBox, DxTagBox },
        data() {
            return {
                filterContainerId: _.uniqueId("rq-filter-container-"),
                DATA_TYPES,
                FILTER_TYPES,
                position: {
                    top: 0,
                    left: 0
                },
                rqFilterOptions: new RqGridFilterOptions(),
                tagBoxValue: [],
                textValue: "",
                numberValue: 0,
                boolValue: null,
                currentTarget: "",
                targetSet: false,
                rangeStartValue: null,
                rangeEndValue: null,
                gridFilterState: {},
                filterExpression: [],
                selectedDateRange: null,
                useNumberRange: false,
                selectedTagItems: [],
                lookupDataSource: [],
                gridScrollOffset: null
            };
        },

        computed: {
            isVisible: {
                get() { return this.visible && this.filterOptionsValid; },
                set(val) { this.$emit("update:visible", _.parseBool(val)); }
            },
            filterOptionsValid() { return !_.isEmpty(this.target) && !_.isEmpty(_.get(this, "column.dataField", null)); },
            containerSelector() { return this.container ? `#${_.trimStart(this.container)}` : null },
            targetSelector() { return this.target ? `#${_.trimStart(this.target)}` : null },
            isInputDisabled() { return this.rqFilterOptions.isInputDisabled(_.cloneDeep(this.rqFilterOptions)); },
            inputDisabledTooltip() { return this.isInputDisabled ? this.rqFilterOptions.inputDisabledMessage : ""; },
            numberInputToggleDisplay() { return `${this.useNumberRange ? "Contains" : "Range"} Filter`; },
            dataField() { return _.get(this, "column.dataField", null); },
            isDateType() { return this.column.dataType === this.DATA_TYPES.date; },
            isDateTimeType() { return this.column.dataType === this.DATA_TYPES.datetime; },
            isStringType() { return this.column.dataType === this.DATA_TYPES.string; },
            isNumberType() { return this.column.dataType === this.DATA_TYPES.number; },
            isBoolType() { return this.column.dataType === this.DATA_TYPES.boolean; },
            isDateFilter() { return this.isDateType || this.isDateTimeType; },
            isRangeInput() { return this.isDateType || this.isDateTimeType || this.useNumberRange; },

            filterType() { return this.getFilterType(); },
            isSelectBoxInput() { return this.filterType === this.FILTER_TYPES.select; },
            isTagBoxInput() { return this.filterType === this.FILTER_TYPES.tags || (this.filterType === this.FILTER_TYPES.default && !_.isEmpty(this.lookupDataSource)); },

            hasValue() { return !_.isEmpty(this.textValue) || _.gt(this.rangeStartValue, 0) || _.gt(this.rangeEndValue, 0); },
            isCurrencyFormat() { return this.column.format === "currency"; },
            numberPrefix() { return this.isCurrencyFormat ? "$" : ""; },
            numberDecimals() { return this.isCurrencyFormat ? 2 : (_.get(this, "column.rqFilter.decimals", 0) || 0); },
            showClearButton() { return this.hasValue && !this.isBoolType && !this.isSelectBoxInput && !this.isTagBoxInput; },
            lookupDisplayExpr() {
                return this.rqFilterOptions.displayExpr
                    || _.get(this, "column.lookup.displayExpr", null);
            },
            lookupValueExpr() {
                return this.rqFilterOptions.valueExpr
                    || _.get(this, "column.lookup.valueExpr", null);
            },
            defaultPlaceholder() { return (this.isTagBoxInput || this.isSelectBoxInput) ? "Select..." : ""; },
            filterPlaceholder() { return this.rqFilterOptions.placeholder || this.defaultPlaceholder; },
            hasItemTemplate() { return !_.isEmpty(this.rqFilterOptions.itemTemplate); },
            lookupItemTemplate() { return this.hasItemTemplate ? "rq-custom-item" : "item"; },
            popoverWrapperAttr() {
                return {
                    class: _.evalCssObject({
                        "rq-grid-filter-popover": true,
                        "rq-grid-default-filter": !this.isTagBoxInput && !this.isDateFilter,
                        "rq-grid-tagbox-filter": this.isTagBoxInput
                    })
                };
            },
            popoverPosition() {
                const self = this;
                let myPosition = "top right";
                let atPosition = "bottom right";
                if(self.isNumberType && self.filterType === FILTER_TYPES.default) {
                    myPosition = "top left";
                    atPosition = "bottom left";
                }
                return {
                    my: myPosition,
                    at: atPosition,
                    offset: { x: 0, y: -10 },
                    boundary: self.containerSelector
                };
            },
            automationIdPrefix() {
                return `fil_${this.automation_id}_${this.column.name}`;
            }
        },

        watch: {
            filterState: {
                handler(newValue, oldValue) {
                    this.gridFilterState = _.cloneDeep(newValue);
                },
                immediate: true
            },
            isVisible(newValue, oldValue) {
                if(_.parseBool(oldValue) || !_.parseBool(newValue)) return;
                this.initializeFilter();
            }
        },

        mounted() {
            document.addEventListener("click", this.onAnyDocumentClick, true);
        },

        beforeUnmount() {
            document.removeEventListener("click", this.onAnyDocumentClick, true);
        },

        methods: {

            // compareItems(o1,o2) {
            //     let expr = this.lookupValueExpr;
            //     return (!_.isNullOrEmpty(expr)
            //                 && _.isObject(o1)
            //                 && _.isObject(o2)
            //                 && _.isEqual(o1[expr], o2[expr]))
            //             || _.isEqual(o1, o2);
            // },

            // combineSelectedItems(dataItems) {
            //     const self = this;
            //     if(_.isEmpty(self.selectedTagItems)) return dataItems;
            //     let arr1 = self.selectedTagItems.slice();
            //     let arr2 = dataItems.slice();

            //     _.forEach(arr1, item1 => {
            //         _.remove(arr2, item2 => self.compareItems(item1, item2));
            //     });

            //     return _.concat(arr1, arr2);
            // },

            initializeFilter() {
                this.rqFilterOptions = new RqGridFilterOptions(
                    _.get(this, "column.rqFilter", {})
                );
                this.toggleGridScrollListener("on");
                this.setDataSource();
                this.parseFilterValue();
                this.$nextTick().then(() => {
                    this.focusInput();
                });
            },

            toggleGridScrollListener(listenerState) {
                let gridScrollable = _.invoke(this, "gridInstance.getScrollable");
                this.gridScrollOffset = null;
                if(_.isEmpty(gridScrollable)) return;
                gridScrollable[listenerState]("scroll", this.onGridScroll);
            },

            show() {
                this.clear();
                this.isVisible = true;
            },

            cancel() {
                this.hide();
                this.clear();
            },

            hide() {
                this.toggleGridScrollListener("off");
                this.isVisible = false;
            },

            clear() {
                this.textValue = null;
                this.rangeStartValue = null;
                this.rangeEndValue = null;
                this.boolValue = null;
                this.selectedDateRange = null;
                this.tagBoxValue = null;
                this.selectedTagItems = [];
                this.useNumberRange = false;
            },

            reset(expr=[]) {
                this.clear();
                this.useNumberRange = false;
                this.gridFilterState = _.isEmpty(expr) ? {} : GridUtil.getStateFromExpr(expr);
                this.filterExpression = expr;
                this.refreshIconStates();
                this.emitChange();
            },

            focusInput() {
                let inputFocusExpr = null;
                if(this.isDateFilter) {
                    inputFocusExpr = "dateFilterComponent.focus";
                }
                else if(this.isBoolType) {
                    inputFocusExpr = "checkAllInput.focus";
                }
                else if(this.isSelectBoxInput) {
                    inputFocusExpr = "dxSelectBoxInput.instance.focus";
                }
                else if(this.isTagBoxInput) {
                    inputFocusExpr = "dxTagBoxInput.instance.focus";
                }
                else if(this.useNumberRange) {
                    inputFocusExpr = "numberRangeInput.focus";
                }
                else {
                    inputFocusExpr = "textInput.focus";
                }
                _.invoke(this, `$refs.${inputFocusExpr}`);

            },

            setDataSource() {
                const self = this;
                let dataSource = self.getConfiguredDataSource();

                if(!self.rqFilterOptions.persistSelectedItems || !_.isFunction(dataSource.load)) {
                    self.lookupDataSource = _.isFunction(dataSource)
                        ? self.lookupDataSource = dataSource()
                        : dataSource;
                    return;
                }

                let tbDataSource = _.cloneDeep(dataSource);
                tbDataSource.load = function(loadOptions) {
                    let sanitizedItems = _.map(self.selectedTagItems, item => _.toPlainObject(item));
                    return dataSource.load(loadOptions, sanitizedItems);
                };
                self.lookupDataSource = tbDataSource;
            },

            parseFilterValue(field) {
                let dataField = field || this.dataField;
                if(!dataField) return;
                this.clear();

                if(_.isEmpty(this.gridFilterState)) return;

                let currentFilterState = this.gridFilterState[dataField];
                if(_.isEmpty(currentFilterState)) return;

                let filters = currentFilterState.filters;
                let value = currentFilterState.value;
                if(_.isEmpty(filters) || _.isNil(value) || (_.isEmpty(value) && _.isString(value))) return;

                this.useNumberRange = _.parseBool(currentFilterState.useNumberRange);

                if(this.isRangeInput) {
                    this.selectedDateRange = currentFilterState.selectedRange;
                    let startValue = _.isArray(value) ? value[0] : value;
                    let endValue = _.isArray(value) ? value[1] || null : null;
                    this.rangeStartValue = this.isDateFilter ? formatDate(startValue) : startValue;
                    this.rangeEndValue = this.isDateFilter ? formatDate(endValue) : endValue;
                }
                else if(this.isBoolType) {
                    this.boolValue = value;
                }
                else if(this.isTagBoxInput) {
                    this.tagBoxValue = value;
                    this.selectedTagItems = currentFilterState.selected.slice();
                }
                else {
                    this.textValue = value;
                }
            },

            setActiveFilterValue() {
                let dataField = this.column.dataField;
                let newFilters = _.clone(this.gridFilterState);
                newFilters[dataField] = this.getActiveFilterState();
                this.gridFilterState = _.omitBy(newFilters, _.isNil);
            },

            setFilterValue(column, values, isRange, operator=null, selected=null) {
                let newFilters = _.clone(this.gridFilterState);
                let filterExpr = null;
                let dataField = column.dataField;
                let filterInfo = {};

                newFilters[dataField] = null;

                if(isRange) {
                    filterInfo.filters = GridUtil.getRangeFilterExpression(column, values);
                }
                else if(_.isArray(values)) {
                    filterInfo.filters = GridUtil.getListFilterExpression(column, values, this.rqFilterOptions.listOperator, this.rqFilterOptions.valueOperator);
                }
                else if(_.isNil(values) || (_.isString(values) && _.isEmpty(values)))
                    filterInfo.filters = null;
                else {
                    let opr = _.isNil(operator) ? _.isBoolean(values) ? "=" : "contains" : operator;
                    filterInfo.filters = column.calculateFilterExpression(values, opr);
                    filterInfo.values = values;
                    filterInfo.filters.filterValues = values;
                }

                if(!_.isNil(filterInfo.filters)) {
                    if(!_.isEmpty(selected)) {
                        filterInfo.selected = selected;
                    }
                    newFilters[dataField] = filterInfo;
                }

                this.gridFilterState = _.omitBy(newFilters, _.isNil);
            },

            getConfiguredDataSource() {
                const self = this;
                return self.rqFilterOptions.dataSource
                    || _.get(self, "column.lookup.dataSource", null)
                    || _.get(self, "column.lookup.data", null)
                    || [];
            },

            getFilterType() {
                let dataSource = this.getConfiguredDataSource();
                let filterType = this.rqFilterOptions.filterType;
                if(filterType === this.FILTER_TYPES.default && !_.isEmpty(dataSource))
                    return this.FILTER_TYPES.tags;
                return filterType;
            },

            getFilterExpression() {
                let filters = GridUtil.getCombinedFilterExpression(this.gridFilterState);

                this.filterExpression = _.isArray(filters) ? filters.slice() : [filters];
                return filters;
            },

            getActiveFilterExpression() {
                if(this.isRangeInput) {

                    if(this.rangeStartValue == null && this.rangeEndValue == null){
                        return [];
                    }

                    return GridUtil.getRangeFilterExpression(this.column, [this.rangeStartValue, this.rangeEndValue]);
                }
                else if(this.isTagBoxInput) {
                    return GridUtil.getListFilterExpression(this.column, this.tagBoxValue, this.rqFilterOptions.listOperator, this.rqFilterOptions.valueOperator);
                }
                else if(this.isBoolType && !_.isNil(this.boolValue)) {
                    return this.column.calculateFilterExpression(this.boolValue, "=");
                }
                else if(_.isNumber(this.textValue) || !_.isEmpty(this.textValue)) {
                    let operator = this.isSelectBoxInput ? "=" : "contains";
                    return this.column.calculateFilterExpression(this.textValue, operator);
                }
                return [];
            },

            getActiveFilterState() {
                let filters = this.getActiveFilterExpression();
                if(_.isEmpty(filters)) return null;
                if(this.isRangeInput) {
                    return {
                        filters,
                        value: filters.filterValues,
                        selectedRange: this.selectedDateRange,
                        useNumberRange: this.useNumberRange
                    };
                }
                else if(this.isTagBoxInput) {
                    return {
                        filters,
                        value: this.tagBoxValue.slice(),
                        selected: this.selectedTagItems.slice()
                    };
                }
                else if(this.isBoolType && !_.isNil(this.boolValue)) {
                    return { filters, value: this.boolValue };
                }
                else if(_.isNumber(this.textValue) || !_.isEmpty(this.textValue)) {
                    return { filters, value: this.textValue };
                }
            },

            applyActiveFilter() {
                const self = this;
                if(!self.gridInstance) return;
                self.$nextTick().then(() => {
                    self.setActiveFilterValue();
                    self.setFilterIconState(this.column);
                    self.applyFilters();
                    self.hide();
                });
            },

            removeActiveFilter() {
                const self = this;
                if(!self.gridInstance) return;
                self.clear();
                self.applyActiveFilter();
            },

            removeColumnFilter(dataField, applyUpdatedFilters=true) {
                this.removeColumnFilters([dataField], applyUpdatedFilters);
            },

            removeColumnFilters(dataFields, applyUpdatedFilters=true) {
                const self = this;
                let newFilters = _.clone(self.gridFilterState);
                _.forEach(dataFields, df => {
                    if(!_.has(newFilters, df)) return;
                    _.set(newFilters, df, null);
                });
                self.gridFilterState = _.omitBy(newFilters, _.isNil);
                if(!applyUpdatedFilters) return;
                self.applyFilters();
            },

            applyFilters(){
                const self = this;
                if(!self.gridInstance) return;

                if(self.clearBeforeNextApply) {
                    self.gridInstance.clearFilter("filterValue");
                    self.$emit("update:clearBeforeNextApply", false);
                }

                let filterExpr = self.getFilterExpression();
                if(_.isEmpty(filterExpr))
                    self.gridInstance.clearFilter();
                else
                    self.gridInstance.filter(filterExpr);

                self.selectedTagItems = [];
                self.filterExpression = filterExpr;
                self.emitChange();
            },

            updateFilter(dataField, values, isRange=false, operator=null, selected=null) {
                const self = this;
                let column = self.gridInstance.columnOption(dataField);
                if(!column) return;
                self.setFilterValue(column, values, isRange, operator, selected);
                self.setFilterIconState(column);
                self.applyFilters();
            },

            updateFilters(filterList) {
                const self = this;
                if(!self.gridInstance) return;
                _.forEach(filterList, f => {
                    let column = self.gridInstance.columnOption(f.dataField);
                    if(!column) return;
                    self.setFilterValue(column, f.values, _.parseBool(f.isRange), f.operator, f.selected);
                });
                self.applyFilters();
            },

            refreshIconStates() {
                let columns = this.gridInstance.getVisibleColumns();
                if(_.isEmpty(columns)) return;
                _.forEach(columns, this.setFilterIconState);
            },

            setFilterIconState(column) {
                const self = this;
                if(!self.gridInstance || !_.parseBool(column.visible) || !_.parseBool(column.allowFiltering) || column.type === "selection") return;
                let filterIconElement = document.getElementById(`rq-filter-${column.headerId}`);
                if(_.isNil(filterIconElement)) return;
                if(self.fieldHasFilter(column.dataField))
                    filterIconElement.classList.add("rq-filter-active");
                else
                    filterIconElement.classList.remove("rq-filter-active");
            },

            fieldHasFilter(dataField=null) {
                let targetField = dataField || this.dataField;
                return _.hasIn(this.gridFilterState, targetField);
            },

            emitChange() {
                const self = this;
                self.$emit("update:filterState", self.gridFilterState);
                self.$nextTick(() => {
                    self.$emit("filter-change", { filterExpr: self.filterExpression });
                });
            },

            onTagValueChanged(e) {
                this.selectedTagItems = e.component.option("selectedItems");
            },

            onGridScroll(e) {
                if(!this.isVisible) return;
                if(_.isNil(this.gridScrollOffset)) {
                    this.gridScrollOffset = e.scrollOffset;
                    return;
                }
                if(_.getNumber(this, "gridScrollOffset.left", 0) === _.getNumber(e, "scrollOffset.left", 0)) return;
                this.cancel();
            },

            onCancel() { this.cancel(); },

            onApply() { this.applyActiveFilter(); },

            onRemove() { this.removeActiveFilter(); },

            onKeyUp(e) {
                if(e.key === "Enter") this.applyActiveFilter();
                if(e.key === "Escape") this.cancel();
            },

            onCustomDateRangeSelected() {
                this.$nextTick(() => {
                    _.invoke(this, "$refs.popoverComponent.instance.repaint");
                });
            },

            onAnyDocumentClick(e) {
                let containerEl = document.querySelector(`#${this.filterContainerId}`);
                let dropdownPopupEl = document.querySelector(`#${this.filterContainerId}_dropdown_popup`);
                const isClickedParent = el => _.isNil(el)
                    || el.contains(e.target)
                    || _.invoke(e, "path.includes", el);
                if(!this.isVisible
                    || isClickedParent(containerEl)
                    || isClickedParent(dropdownPopupEl)) return;
                this.cancel();
            }
        }
    };

</script>
