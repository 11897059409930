<template>
    <dx-date-box
        ref="dateBox"
        :id="idValue"
        :class="classAttr"
        :input-attr="inputAttrValue"
        :wrapper-attr="wrapperAttr || elementAttr"
        :access-key="accessKey"
        :active-state-enabled="activeStateEnabled"
        :apply-button-text="applyButtonText"
        :apply-value-mode="applyValueModeValue"
        :buttons="buttons"
        :calendar-options="calendarOptions"
        :cancel-button-text="cancelButtonText"
        :date-out-of-range-message="dateOutOfRangeMessage"
        :defer-rendering="deferRenderingValue"
        :disabled="disabled"
        :disabled-dates="disabledDates"
        :display-format="displayFormatValue"
        :date-serialization-format="dateSerializationValue"
        drop-down-button-template="drop-down-icon"
        :drop-down-options="dropDownOptions"
        :focus-state-enabled="focusStateEnabled"
        :height="height"
        :hint="hint"
        :hover-state-enabled="hoverStateEnabled"
        :interval="interval"
        v-model:is-valid="dxIsValid"
        :invalid-date-message="invalidDateMessage"
        :max="maxValue"
        :max-length="maxLength"
        :min="minValue"
        v-model:opened="isOpened"
        :open-on-field-click="openOnFieldClick"
        :picker-type="pickerTypeValue"
        :placeholder="placeholder"
        :read-only="readOnly"
        :show-clear-button="showClearButtonValue"
        :show-drop-down-button="showDropDownButton"
        :styling-mode="stylingMode"
        :type="type"
        :show-analog-clock="showAnalogClock"
        :use-mask-behavior="useMaskBehavior"
        :visible="visible"
        :width="width"
        v-model="dxValue"
        @drop="onNativeDrop"
        @key-up="onKeyUp"
        @key-down="onKeyDown"
        @value-changed="onDxValueChanged"
        @change="$emit('change', $event)"
        @closed="$emit('closed', $event)"
        @content-ready="$emit('contentReady', $event)"
        @enter-key="$emit('enterKey', $event)"
        @focus-in="$emit('focusIn', $event)"
        @focus-out="onFocusOut"
        @initialized="$emit('initialized', $event)"
        @input="$emit('input', $event)"
        @opened="$emit('opened', $event)"
        @option-changed="$emit('optionChanged', $event)">
        <slot></slot>
        <template #drop-down-icon>
            <div class="rqdx-date-box-icon text-center d-flex" :tabindex="defaultButtonDisplay || preventDropDownButtonFocus ? -1 : tabIndex" @focus="onDropDownIconFocus">
                <FontAwesomeIcon :icon="type === 'time' ? 'far fa-clock' : 'far fa-calendar-alt'" />
            </div>
        </template>
    </dx-date-box>
</template>
<script>
    import { DateTime } from "luxon";
    import { closeDropDownOnDialogMouseEvent } from "./utils";
    const DEFAULT_FORMAT_STRING = "yyyy-MM-dd'T'HH:mm:ss";
    const dxEmits = ["change", "closed", "contentReady", "enterKey", "focusIn", "focusOut", "initialized", "input", "keyDown", "keyUp", "opened", "optionChanged", "valueChanged"];
    export default {
        name: "rqdxDateBox",
        emits: [ ...dxEmits, "update:modelValue"],
        props: {
            //RQ Custom Props
            id: { type: String, default:null },
            automation_id: { type: String, default: "" },
            defaultButtonDisplay: { type: Boolean, default: false },
            preventDropDownButtonFocus: { type: Boolean, default: false },
            autoOpen: {type: Boolean, default: false },
            modelValue: { default: null },

            //Corresponding DxDateBox props
            accessKey: String,
            activeStateEnabled: { type: Boolean, default: true },
            applyButtonText: { type: String, default: "OK" },
            applyValueMode: { type: String, default: "instantly" },
            buttons: Array,
            calendarOptions: Object,
            cancelButtonText: { type: String, default: "Cancel" },
            dateSerializationValue: { type: String, default: "yyyy-MM-ddTHH:mm:ss" },
            dateOutOfRangeMessage: String,
            deferRendering: { type: Boolean, default: null },
            disabled: { type: Boolean, default: false },
            disabledDates: [Array, Function],
            displayFormat: [Object, Function, String],
            elementAttr: Object,
            wrapperAttr: Object,
            focusStateEnabled: { type: Boolean, default: true },
            height: [Function, Number, String],
            hint: String,
            hoverStateEnabled: { type: Boolean, default: true },
            inputAttr: { type: Object, default: () => null },
            interval: { type: Number, default: 15 },
            isValid: { type: Boolean, default: true },
            invalidDateMessage: String,
            max: {},
            maxLength: [Number, String],
            min: {},
            name: String,
            opened: { type: Boolean, default: false },
            openOnFieldClick: { type: Boolean, default: false },
            pickerType: { type: String, default: null },
            placeholder: String,
            readOnly: { type: Boolean, default: false },
            showAnalogClock: { type: Boolean, default: false },
            showClearButton: { type: Boolean, default: null },
            showDropDownButton: { type: Boolean, default: true },
            stylingMode: { type: String, default: "outlined" },
            tabIndex: { type: Number, default: 0 },
            type: { type: String, default: "date" },
            useMaskBehavior: { type: Boolean, default: true },
            visible: { type: Boolean, default: true },
            width: [Function, Number, String],
            datePart: { type: [String, Date], default: null },
        },

        data () {
            const self = this;
            return {
                dxValue: null,
                // dxListeners: _.omit(self.$listeners, ["keyUp","valueChanged"]),
                isOpened: self.opened,
                dxIsValid: true,
                dropDownOptions: {
                    hideOnOutsideClick: true,
                    hideOnParentScroll: true
                }
            };
        },

        computed: {
            idValue() { return _.isNil(this.id) ? _.uniqueId("dtp_rqdx_datebox_") : this.id; },
            automationId() { return this.automation_id || this.idValue; },
            dxInstance() { return _.get(this, "$refs.dateBox.$_instance", null); },
            classAttr() { return { "form-control": true, "input-group-display": !this.defaultButtonDisplay }; },
            deferRenderingValue() { return _.isNil(this.deferRendering) ? this.type !== "time" : this.deferRendering; },
            displayFormatValue() {
                if(!_.isNil(this.displayFormat)) return this.displayFormat;
                return this.type === "date"
                    ? "MM/dd/yyyy"
                    : this.type === "time"
                        ? "hh:mm a"
                        : "MM/dd/yyyy hh:mm a";
            },
            inputAttrValue() {
                let defaultAttrs = { automation_id: this.automationId, tabindex: this.tabIndex.toString(), autocomplete: "chrome-off" };
                return _.isNil(this.inputAttr) ? defaultAttrs : _.defaults({}, this.inputAttr, defaultAttrs);
            },
            minValue() { return _.parseDateString(this.min); },
            maxValue() { return _.parseDateString(this.max); },
            pickerTypeValue() { return _.isNil(this.pickerType) ? (this.type === "time" ? "list" : "calendar") : this.pickerType; },
            applyValueModeValue() { return _.toLower(this.type) === "datetime" ? "useButtons" : this.applyValueMode; },
            showClearButtonValue() { return _.isNil(this.showClearButton) ? this.type !== "time" : this.showClearButton; },
            // Format disparities between momentjs and devextreme handlers.
            // isUtc() { return this.dateSerializationValue == 'yyyy-MM-ddTHH:mm:ssZ'; },
            // momentEmitFormat() { return this.isUtc ? 'YYYY-MM-DDTHH:mm:ssZ' : 'YYYY-MM-DDTHH:mm:ss'; },
        },
        watch: {
            modelValue: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue || _.parseDateString(newValue) === _.parseDateString(this.dxValue)) return;

                    //allow DxDateBox to handle values directly
                    this.dxValue = this.parseDateValue(newValue);

                    // This provides newValue to both parameters because this is the external entry point.
                    // let finalMomentValue = this.getDerivedMoment(newValue);
                    // this.dxValue = finalMomentValue.isValid() ? finalMomentValue.format(this.momentEmitFormat) : null;
                },
                immediate: true
            },
            dxValue: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue || (_.parseDateString(newValue) === _.parseDateString(this.modelValue) && _.isNil(newValue) === _.isNil(this.modelValue))) return;

                    //allow DxDateBox to handle values directly
                    this.$emit("update:modelValue", this.parseDateValue(newValue));

                    // let finalMomentValue = this.getDerivedMoment(newValue);
                    // this.$emit("update:modelValue", finalMomentValue.isValid() ? finalMomentValue.format(this.momentEmitFormat) : null);
                }
            },
            isValid: {
                handler(newValue, oldValue) {
                    if(newValue === oldValue || newValue === this.dxIsValid) return;
                    this.dxIsValid = newValue;
                },
                immediate: true
            },
            dxIsValid(newValue, oldValue) {
                if(newValue === oldValue || newValue === this.isValid) return;
                this.$emit("update:isValid", newValue);
            }
        },

        beforeUnmount() {
            this.close();
        },

        created() {
            const self = this;
            //RQO-10202 - temporary workaround to resolve "time" dropdown width issue
            if(self.type !== "time") return;
            self.dropDownOptions.width = function() {
                let elementWidth = _.getNumber(self, "$el.clientWidth", 0);
                return elementWidth || 500;
            };
        },

        mounted() {
            //reapply inputAttr to force correct tabindex value
            this.invokeInstanceMethod("option", "inputAttr", _.clone(this.inputAttrValue));
        },

        methods: {
            parseDateValue(val=null) {
                let valueDt = _.parseLuxonDateTime(val);
                let datePartDt = _.parseLuxonDateTime(this.datePart);

                if(_.isNil(valueDt) || !valueDt.isValid) return null;

                //default date only values to midnight
                if (this.type === "date") {
                    return valueDt
                        .startOf("day")
                        .toFormat(DEFAULT_FORMAT_STRING);
                }

                //combine date part and current value if "datePart" is provided
                if (this.type === "time" && !_.isNil(datePartDt) && datePartDt.isValid) {
                    return DateTime
                        .fromObject({
                            month: datePartDt.month,
                            day: datePartDt.day,
                            year: datePartDt.year,
                            hour: valueDt.hour,
                            minute: valueDt.minute,
                            second: valueDt.second
                        })
                        .toFormat(DEFAULT_FORMAT_STRING);
                }

                return valueDt
                    .toFormat(DEFAULT_FORMAT_STRING);
            },

            onNativeDrop(e) {
                const self = this;
                self.stopEventPropagation(e);
                let dropItem = _.get(e, "dataTransfer.items[0]", null);
                if(_.isNil(dropItem)) return;
                dropItem.getAsString(val => {
                    if(!_.isValidDate(val) || _.parseDateString(val) === _.parseDateString(self.dxValue)) return;
                    self.dxValue = _.parseDateString(val);
                    self.focus();
                });
            },

            onNativeKeyDown(e) {
                //TODO: Need to find a way to capture this event specifically on the DX DropDown Button with the changes to how Vue 3 handles native events

                console.log("NativeKeyDown...", e);
                let targetIsDropDownButton =  e.event.target.classList.contains("dx-dropdowneditor-button");
                let toggleDropDown = targetIsDropDownButton && (e.event.key === "Enter" || e.event.key === " ");
                let closeDropDown = targetIsDropDownButton && this.isOpened && e.event.key === "Tab" && !e.event.shiftKey;

                if (!toggleDropDown && !closeDropDown) return;

                if(e.event.key !== "Tab") this.stopEventPropagation(e.event);

                if(toggleDropDown)
                    this.isOpened = !this.isOpened;
                else if(closeDropDown)
                    this.close();
            },

            onKeyUp(e) {
                this.$emit("keyUp", e);
                if(!this.autoOpen || e.event.key !== "Tab" && this.isOpened) return;
                this.open();
            },

            onKeyDown(e) {
                this.$emit("keyDown", e);
                if (e.event.key !== "F6") return;
                this.stopEventPropagation(e.event);
                this.dxValue = this.parseDateValue(DateTime.now());
                this.close();
                this.$nextTick(() => {
                    this.focus();
                });
            },

            onDxValueChanged(e) {
                if(_.isNil(e.value)) this.close();
                this.$nextTick(() => {
                    this.$emit("valueChanged", e);
                });
            },

            onDropDownIconFocus(e) {
                let buttonElement = _.get(e, "target.parentElement", null);
                if(this.defaultButtonDisplay || this.preventDropDownButtonFocus || !e.target.classList.contains("rqdx-date-box-icon") || e.target.tabIndex < 0 || _.isNil(buttonElement)) return;
                buttonElement.tabIndex = e.target.tabIndex;
                e.target.tabIndex = -1;
                e.target.parentElement.focus();
            },

            onFocusOut(e) {
                this.$emit("focusOut", e);
                closeDropDownOnDialogMouseEvent(e);
            },

            stopEventPropagation(e) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
            },

            // getValidNow() {
            //     let nowDate = new Date();
            //     let minDate = _.parseDate(this.min);
            //     let maxDate = _.parseDate(this.max);
            //     if(!_.isNil(minDate) && minDate > nowDate) return moment(minDate).toISOString();
            //     if(!_.isNil(maxDate) && maxDate < nowDate) return moment(maxDate).toISOString();
            //     return moment().toISOString();
            // },

            open() { this.isOpened = true; },

            close() { this.isOpened = false; },

            focus() {
                if(this.disabled) return;
                this.invokeInstanceMethod("focus");
            },

            invokeInstanceMethod(method, ...params) {
                return _.invoke(this, `$refs.dateBox.$_instance.${method}`, ...params);
            }
        }
    };
</script>