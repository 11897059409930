// Import vue components
import * as components from "./lib-components/index";
import { closeDropDownOnDialogMouseEvent } from "./lib-components/utils";
import { DxTextBox, DxSelectBox, DxTagBox, DxAutocomplete, DxDateBox, DxPopover } from "devextreme-vue";
import SelectBox from "devextreme/ui/select_box";
import TagBox from "devextreme/ui/tag_box";
import DataGrid from 'devextreme/ui/data_grid';

// install function executed by app.use()
const install = function installRqDevextremeVue(app) {

    let dxDefaultOptions = {
        options: {
            inputAttr: { autocomplete: "chrome-off" },
            elementAttr: { class: "form-control" },
            searchEnabled: true,
            onFocusOut: closeDropDownOnDialogMouseEvent
        }
    };

    SelectBox.defaultOptions(dxDefaultOptions);
    TagBox.defaultOptions(dxDefaultOptions);

    //RQO-3508 The field chooser sorts columns according to their visible index.
    //We want to detach that direct association and have column chooser in alphabetical order.
    //Adding the following private function to do so within DevEx DataGrid:
    DataGrid.registerModule("columnChooserSorting", {
        extenders: {
            controllers: {
                columns: {
                    getChooserColumns(loadAllColumns) {
                        var result = this.callBase(loadAllColumns);
                        return result.sort(function(column1, column2) {
                            let cap1 = column1.caption || column1.dataField || "";
                            let cap2 = column2.caption || column2.dataField || "";
                            return cap1.localeCompare(cap2);
                        });
                    }
                }
            }
        }
    });

    const allComponents = {
        DxTextBox,
        DxSelectBox,
        DxTagBox,
        DxAutocomplete,
        DxDateBox,
        DxPopover,
        ...components
    };

    Object.entries(allComponents).forEach(([componentName, component]) => {
        app.component(componentName, component);
    });
};

// Create module definition for app.use()
export default install;

export { closeDropDownOnDialogMouseEvent };

// To allow individual component use, export components
// each can be registered via app.component()
export * from "./lib-components/index";
