<template>
    <div :class="{'form-group':true, 'form-required':required, 'mb-0': renderBelowSlot, 'has-error':hasError, [actionClass]: showAction }">
        <div class="row">
            <label class="col col-form-label pt-0" v-if="label" :for="labelFor" v-html="label" />
            <div v-show="showAction" :class="{'col-auto form-group-action ms-auto': true, 'px-1': hasInfoToolTip}">
                <slot name="action">
                    <b-btn :automation_id="actionAutomationId" variant="link" class="btn-theme" @click="$emit('action-click')">{{actionLabel}}</b-btn>
                </slot>
            </div>
            <div v-if="hasInfoToolTip" :class="{'col-auto ps-1':true, 'ms-auto':!showAction}" :title="infoToolTip" v-rq-tooltip.hover.top>
                <FontAwesomeIcon icon="fas fa-info-circle" class="text-dark" />
            </div>
        </div>
        <slot></slot>
        <div v-if="renderBelowSlot" class="row">
            <div ref="belowSlotElement" class="col-auto form-group-action ms-auto">
                <slot name="below"></slot>
            </div>
        </div>
    </div>
</template>
<script>
    import { computed, onMounted, ref } from "vue";
    export default {
        name: "RqActionFormGroup",
        props: {
            actionAutomationId: { type: String, default: null },
            label: { type: String, default: null },
            labelFor: { type: String, default: null },
            actionClass: { type: String, default: "rq-action-visible" },
            actionLabel: { type: String, default: null },
            showAction: { type: Boolean, default: false },
            required: {type: Boolean, default: false },
            hasError: {type: Boolean, default: false },
            infoToolTip: { type: String, default: null },
        },
        setup(props) {
            const belowSlotElement = ref(null);
            const renderBelowSlot = ref(true);
            const hasInfoToolTip = computed(() => !_.isEmpty(props.infoToolTip));

            const elRefIsEmpty = el => _.isEmpty(el.value?.textContent);

            onMounted(() => {
                renderBelowSlot.value = !elRefIsEmpty(belowSlotElement);
            });

            return {
                belowSlotElement,
                renderBelowSlot,
                hasInfoToolTip
            };
        }
    }
</script>
